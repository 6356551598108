/* body {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
	margin: 0;
	box-sizing: border-box;
	background-color: #1b1b1e;
	font-family: Montserrat, sans-serif;
	color: #b1b1b3;
} */

.window_page {
  margin: 0;
	width: 100%;
  height: 100vh;
  background-color: rgb(40, 40, 40);
  display: grid;
  place-items: center;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.window.animate {
	-webkit-animation: barrelRoll 2s ease-in-out;
	animation: barrelRoll 2s ease-in-out;
}

p,
ul,
ol {
	list-style-type: none;
	/* list-style: none; */
	margin: 0;
	padding: 0;
}

.window {
	max-width: 99vh;
	max-height: 95vh;
	margin: auto;
	background-color: #000;
	box-shadow: 0 0 25px -7px #000;
	resize: horizontal;
	/* padding-bottom: 5px; */
}

.window-top {
	display: flex;
	align-items: center;
	height: 30px;
	background-color: #202020;
}

/* 	* { outline: solid 0.05rem hsla(210, 100%, 100%, 0.5); } */


.window__controls {
	display: inline-flex;
}

.window__controls span {
	width: 15px;
	height: 15px;
	margin-left: 10px;
	border-radius: 50%;
}

.window__controls span:nth-child(1) {
	background-color: #b22222;
}
.window__controls span:nth-child(2) {
	background-color: #d6bf3e;
}
.window__controls span:nth-child(3) {
	background-color: #4db434;
}

.window__title {
	flex: 1;
	text-align: center;
	color: #b1b1b3;
}

.window-main {
	padding: 0.5em;
	color: #4e4e4e;
	max-height: 50em;
	overflow-y: auto;
	overflow-x: auto;
}

.window-main ol {
	counter-reset: item;
	font-family: "Source Code Pro", monospace;
	white-space: nowrap;
}

.window-main ol li {
	/* margin-bottom: 3px; */
  display: block;
  margin-bottom: .5em;
  margin-left: 2em;
}

.window-main ol li:before {
	content: counter(item) "  ";
	counter-increment: item;
	padding: 0 10px;

  /* display: inline-block; */
  /* content: counter(item) ") "; */
  /* counter-increment: item; */
  width: 2em;
  margin-left: -2em;
}

.window-main ol li:hover {
  font-style: italic;
  /* text-emphasis: bold; */
  /* text-decoration: */
  /* text-size-adjust: 110%; */
}

.line-numbering {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1; /* make sure the line numbering is on top of the scrolling content */
}

table {
  border-collapse: collapse;
  width: 100%;
}

td {
  border: 1px solid #ccc;
  padding: 5px;
}

td.line-number {
  position: sticky;
  left: 0;
  z-index: 1;
  /* background-color: #fff; */
}

.window-bottom {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: right; */
	height: 30px;
	/* border: white; */
	border-width: 1px;
	position: relative;
}

.window-bottom-statictext {
	color: #b1b1b3;
	text-align: right;
	margin: 0 2%;
	/* position: absolute; */
	bottom: 0;
}

.window-bottom-statictext select {
	background-color: rgba(0, 0, 0, 0);
	color: #b1b1b3;
	border: none;
	/* text-align: right; */
	/* margin: 0 2%; */
}

.line-link {
	color: orange;
	text-decoration: none;
}

.key {
	color: #F92672;
}
.attr {
	color: #8dc6e3;
}
.string {
	color: #E6DB74;
}
.boolean {
	color: #AE81FF;
}
.text {
	color: #d4d4d4;
}
.number {
	color: #AE81FF;
}
.comment {
  color: #75715E;
}

@media only screen and (max-width: 601px) {
	.window {
		/* width: 350px;
		height: 350px; */
		font-size: 0.65em;
		margin-top: 5%;
	}

	.window__controls span {
		width: 10px;
		height: 10px;
		margin-left: 5px;
	}

	.window-main ol li:before {
		padding: 0;
	}
}

@-webkit-keyframes barrelRoll {
	0%  {transform: rotate(0deg)}
	80% {transform: rotate(370deg)}
	100% {transform: rotate(360deg)}
}

@keyframes barrelRoll {
	0%  {transform: rotate(0deg)}
	80% {transform: rotate(370deg)}
	100% {transform: rotate(360deg)}
}