body {
  margin: 0;
  overflow: hidden;
}

sky {
  display: block;
  background: black;
  width: 100vw;
  height: 100vh;
}
star {
  border-radius: 50%;
  background: white;
  position: absolute;
  animation: star linear infinite; 
  @for $i from 1 through 70 {
    &:nth-child(#{$i}) {
      $size: random(7) + 3px;
      width: $size;
      height: $size;
      animation-duration: random(30) + 15s;
      animation-delay: random(40) - 40s;
      top: random(101) - 1vh;
    }
  }
}
@keyframes star {
  from { transform: translate3d(-100%, 0, 1px); }
  to { transform: translate3d(100vw, 0, 1px); }
}

// * { outline: solid 0.05rem hsla(210, 100%, 100%, 0.5); }

.boi {
  position: absolute;
  left: 0%;
  top: 50%;
  // transform: scale(10%);
  animation: eject 100s infinite linear;
  animation-delay: 60s; // one minute
  transform-origin: 13vmin 25vmin;
  transform: translate(-50vmin, -20vmin);
  // transform: translate3d(-50vmin, -20vmin, 0px) rotate(0turn);
  // transform: translate3d(10px, 0, 100px);
  z-index: 10;

	box-shadow: 0 0 25px -7px #000;
}

@keyframes eject {
  0% {
    transform: translate(-50vmin, -20vmin) rotate(0turn) scale(10%);
  }
  50%, 100% {
    transform: translate(100vw, -20vmin) rotate(-2turn) scale(10%);
  }
}

.eye {
  position: absolute;
  left: 13vmin;
  top: 9vmin;
  border: 6px solid black;
  width: 10vmin;
  height: 11vmin;
  border-radius: 26vmin;
  // transform: rotate(18deg) scale(1, 0.5);
  transform: scale(1, 0.5);
  border-top-width: 12px;
  border-bottom-width: 12px;
  background: radial-gradient(ellipse at 31% 20%, #f9fff7 15%, #fff0 20%), radial-gradient(ellipse at 50% 40%, #82c9e4 65%, #fff0 70%), radial-gradient(ellipse at 60% 30%, #40646f 100%, #fff0 100%);
  background-size: 140% 100%, 96% 80%, 100% 100%;
  background-repeat: no-repeat;
}

.belly {
  position: absolute;
  width: 15vmin;
  height: 25vmin;
  background: #0c9fc4 radial-gradient(ellipse at 42% 33%, #14ebe1 50%, #fff0 52%);
  border-radius: 10vmin;
  top: 7vmin;
  left: 6vmin;
  // transform: rotate(10deg);
  background-size: 140% 114%;
  border: 7px solid black;
}

.backpack {
  position: absolute;
  left: 1.2vmin;
  top: 14vmin;
  background: #14ebe1;
  width: 7vmin;
  height: 11vmin;
  border-radius: 3vmin;
  // transform: rotate(7deg);
  border: 7px solid black;
}

.leftleg {
  position: absolute;
  left: 1vmin;
  top: 25vmin;
  width: 11vmin;
  height: 11vmin;
  background: 
    radial-gradient(ellipse at 20% 30%, #fff0 38%, black 39%, black 44%, #0c9fc4 45%);
    // radial-gradient(ellipse at 20% 70%, #0c9fc4 12%, black 15%, #fff0 18%);

  background-repeat: no-repeat;
  border-radius: 20vmin;
  // background-size: 150% 96%, 100% 100%;
  border: 7px solid black;
  transform: rotate(5deg);
  z-index: 50 + 5;
  clip-path: ellipse(80% 45% at 75% 90%);

  // &:not(:nth-child(2)) {
  //   clip-path: polygon(-10% 110%, 100% 110%, 110% 60%, 70% 20%, -5% 30%);
  // }
  // &:nth-child(2) {
  //   filter: url(#inset);
  // }
}

.rightleg {
  position: absolute;
  left: 6vmin;
  top: 27vmin;
  width: 11vmin;
  height: 11vmin;
  background: 
    radial-gradient(ellipse at 20% 30%, #fff0 38%, black 39%, black 44%, #0c9fc4 45%);
    // radial-gradient(ellipse at 20% 70%, #0c9fc4 12%, black 15%, #fff0 18%);

  background-repeat: no-repeat;
  border-radius: 20vmin;
  // background-size: 150% 96%, 100% 100%;
  border: 7px solid black;
  transform: rotate(-10deg);
  z-index: 50 + 5;
  clip-path: ellipse(80% 45% at 75% 90%);

  // &:not(:nth-child(2)) {
  //   clip-path: polygon(-10% 110%, 100% 110%, 110% 60%, 70% 20%, -5% 30%);
  // }
  // &:nth-child(2) {
  //   filter: url(#inset);
  // }
}

// .rightleg {
//   position: absolute;
//   left: 4vmin;
//   top: 28vmin;
//   width: 11vmin;
//   height: 11vmin;
//   background: radial-gradient(ellipse at 20% 70%, #0c9fc4 15%, #fff0 15%), radial-gradient(ellipse at 0% 29%, #fff0 40%, #0c9fc4 40%);
//   background-repeat: no-repeat;
//   border-radius: 20vmin;
//   background-size: 150% 96%, 100% 100%;
//   filter: url(#inset);
// }

// h1 {
//   position: absolute;
//   color: white;
//   font-family: arial, sans-serif;
//   left: 0;
//   top: 50%;
//   width: 100%;
//   font-weight: 400;
//   font-size: 20px;
//   text-align: center;
//   transform: translateY(-50%);
//   @media (min-width: 600px) {
//     font-size: 30px;
//   }
// }
// span {
//   display: inline-block;
//   &.w { display: inline; }
//   @for $i from 1 through 17 {
//     &:nth-child(#{$i}) {
//       animation: type#{$i} 5s infinite linear both;
//       @keyframes type#{$i} {
//         0%, #{$i * 1.5 + 20}% {
//           width: 0px;
//           opacity: 0;
//         }
//         #{$i * 1.5 + 20.5}%, 90% {
//           width: auto;
//           opacity: 1;
//         }
//         95%, 100% {
//           opacity: 0;
//         }
//       }
//     }
//   }
// }