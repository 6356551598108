main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

	/* * { outline: solid 0.05rem hsla(210, 100%, 100%, 0.5); } */

.carousel {
  position: relative;
  /* height: 400px; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  overflow: hidden;
}

.carousel div {
  /* position: absolute; */
  transition: transform 1s, left 1s, opacity 1s, z-index 0s;
  opacity: 1;
}

.carousel div img {
  width: 400px;
  transition: width 1s;
}

.selected {
  z-index: 10;
  left: 50%;
  /* transform: translateY(0px  ) translateX(-50%); */
}

.next {
  z-index: 5;
  left: 70%;
  /* transform: translateY(-100%) translateX(50%); */
}

.next img {
  width: 300px;
}

/*

.hideLeft {
  left: 0%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

.hideLeft img {
  width: 200px;
}

.hideRight {
  left: 100%;
  opacity: 0;
  transform: translateY(50%) translateX(-50%);
}

.hideRight img {
  width: 200px;
}

.prev {
  z-index: 5;
  left: 30%;
  transform: translateY(50px) translateX(-50%);
}

.prev img {
  width: 300px;
}

.prevLeftSecond {
  z-index: 4;
  left: 15%;
  transform: translateY(50%) translateX(-50%);
  opacity: .7;
}

.prevLeftSecond img {
  width: 200px;
}


.nextRightSecond {
  z-index: 4;
  left: 85%;
  transform: translateY(50%) translateX(-50%);
  opacity: .7;
}

.nextRightSecond img {
  width: 200px;
} 

*/

.buttons {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
}
